import React from "react";
import principal from "../assets/images/viceprincipal.jpg";

const AboutPrincipal = () => {
  return (
    <section className="team-details">
      <div className="container">
        <div className="row">
          <div className="row justify-content-between">
            <div className="col-lg-5">
              <div className="team-details__content">
                <h2 className="team-details__title title">Principal's Desk </h2>
                <p className="team-details__text">
                  I strongly believe that education is a collaborative effort that involves professional administrators, committed teachers and motivated students. We in SAS Surat strongly presume that schooling is a collaborative art; everyone needs to work together towards that goal. So the partnership between students, parents, teachers and support staff is salient to pursue excellence and also innovative teaching methodologies encourage students to develop critical-thinking and learn through inquiry and reason, to take ownership for his/her choices and to set goals to realize their own unique potential.<br />
                  The powerful learning and teaching occurs under a shared spirit of respect which creates a passionate schooling experience and empowering our children in such a manner that they act as representatives of a meaningful and value based society.<br />
                  I sincerely thank to all my parents who have their faith in our school. I assure you for the overall growth and holistic development of your child.<br />
                  SAS Surat takes care of the quality academic standard and strongly believes that creativity is that the essence of learning. I wish to start with the words of Albert Einstein: “The true sign of intelligence isn't knowledge but imagination”.
                </p>

              </div>
            </div>
            <div className="col-lg-6">
              <div className="team-one__single">
                <div className="team-one__image">
                  <img src={principal} alt="" />
                </div>
                <div className="team-one__content">
                  <h2 className="team-one__name">
                    <a href="#">Regards</a>
                  </h2>
                  <p className="team-one__designation">Mrs. Deepa.S</p>
                  <p className="team-one__designation">M.C.A, B.Ed, M.Ed.</p>
                  <p className="team-one__designation"><strong>Experience - 15+ years</strong></p>
                </div>
                {/* <div className="team-one__social">
                <a href="#none">
                  <i className="fab fa-twitter"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-facebook-square"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-pinterest-p"></i>
                </a>
                <a href="#none">
                  <i className="fab fa-instagram"></i>
                </a>
              </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutPrincipal;
